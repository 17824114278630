<template>
  <div class="container">
    <a :href="url"><img src="@/assets/activities/guangda/4.jpg" /></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
    };
  },
  created() {
    this.$http.post("/applet/sys/generate_scheme").then((result) => {
      this.url = result.data.data;
    });
  },
};
</script>

<style lang="scss">
.container {
  img {
    width: 100%;
  }
}
</style>
